export const VERIFY_CALENDAR_REQUEST = 'VERIFY_CALENDAR_REQUEST';
export const VERIFY_CALENDAR_REQUEST_SUCCESS = 'VERIFY_CALENDAR_REQUEST_SUCCESS';
export const VERIFY_CALENDAR_REQUEST_FAILURE = 'VERIFY_CALENDAR_REQUEST_FAILURE';

export const VERIFY_NEW_CALENDAR_REQUEST = 'VERIFY_NEW_CALENDAR_REQUEST';
export const VERIFY_NEW_CALENDAR_REQUEST_SUCCESS = 'VERIFY_NEW_CALENDAR_REQUEST_SUCCESS';
export const VERIFY_NEW_CALENDAR_REQUEST_FAILURE = 'VERIFY_NEW_CALENDAR_REQUEST_FAILURE';

export const GET_INTERVIEWER_CALENDAR = 'GET_INTERVIEWER_CALENDAR';
export const GET_INTERVIEWER_CALENDAR_SUCCESS = 'GET_INTERVIEWER_CALENDAR_SUCCESS';
export const GET_INTERVIEWER_CALENDAR_FAILURE = 'GET_INTERVIEWER_CALENDAR_FAILURE';

export const POST_INTERVIEWER_CALENDAR = 'POST_INTERVIEWER_CALENDAR';
export const POST_INTERVIEWER_CALENDAR_SUCCESS = 'POST_INTERVIEWER_CALENDAR_SUCCESS';
export const POST_INTERVIEWER_CALENDAR_FAILURE = 'POST_INTERVIEWER_CALENDAR_FAILURE';

export const POST_CANDIDATE_CALENDAR = 'POST_CANDIDATE_CALENDAR';
export const POST_CANDIDATE_CALENDAR_SUCCESS = 'POST_CANDIDATE_CALENDAR_SUCCESS';
export const POST_CANDIDATE_CALENDAR_FAILURE = 'POST_CANDIDATE_CALENDAR_FAILURE';

export const GET_RESCHEDULE_DATA = 'GET_RESCHEDULE_DATA';
export const GET_RESCHEDULE_DATA_SUCCESS = 'GET_RESCHEDULE_DATA_SUCCESS';
export const GET_RESCHEDULE_DATA_FAILURE = 'GET_RESCHEDULE_DATA_FAILURE';

export const POST_RESCHEDULE_DATA = 'POST_RESCHEDULE_DATA';
export const POST_RESCHEDULE_DATA_SUCCESS = 'POST_RESCHEDULE_DATA_SUCCESS';
export const POST_RESCHEDULE_DATA_FAILURE = 'POST_RESCHEDULE_DATA_FAILURE';

export const CALENDAR_CONNECT = 'CALENDAR_CONNECT';
export const CALENDAR_CONNECT_SUCCESS = 'CALENDAR_CONNECT_SUCCESS';
export const CALENDAR_CONNECT_FAILURE = 'CALENDAR_CONNECT_FAILURE';

export const CHECK_CALENDAR_CONNECTION = 'CHECK_CALENDAR_CONNECTION';
export const CHECK_CALENDAR_CONNECTION_SUCCESS = 'CHECK_CALENDAR_CONNECTION_SUCCESS';
export const CHECK_CALENDAR_CONNECTION_FAILURE = 'CHECK_CALENDAR_CONNECTION_FAILURE';

export const CANCEL_CALENDAR_INTERVIEW = 'CANCEL_CALENDAR_INTERVIEW';
export const CANCEL_CALENDAR_INTERVIEW_SUCCESS = 'CANCEL_CALENDAR_INTERVIEW_SUCCESS';
export const CANCEL_CALENDAR_INTERVIEW_FAILURE = 'CANCEL_CALENDAR_INTERVIEW_FAILURE';

export const RESCHEDULE_CALENDAR_INTERVIEW = 'RESCHEDULE_CALENDAR_INTERVIEW';
export const RESCHEDULE_CALENDAR_INTERVIEW_SUCCESS = 'RESCHEDULE_CALENDAR_INTERVIEW_SUCCESS';
export const RESCHEDULE_CALENDAR_INTERVIEW_FAILURE = 'RESCHEDULE_CALENDAR_INTERVIEW_FAILURE';

export const PANELIST_SLOTS = 'PANELIST_SLOTS';
export const PANELIST_SLOTS_SUCCESS = 'PANELIST_SLOTS_SUCCESS';
export const PANELIST_SLOTS_FAILURE = 'PANELIST_SLOTS_FAILURE';
