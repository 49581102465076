import React from 'react';
import logo from '../assets/images/incruiter-main-logo.svg';

const Header = ({ isMobileCenter }) => {
    return (
        <div className={`header ${isMobileCenter ? 'header-center' : ''}`}>
            <img src={logo} className="image" />
        </div>
    );
};

export default Header;
