import { combineReducers } from 'redux';
import CalendarR from './CalendarReducer';

const appReducer = combineReducers({ CalendarR });

const reducers = (state, action) => {
    return appReducer(state, action);
};

export default reducers;
