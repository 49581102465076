import CryptoJS from 'crypto-js';
import { format, parse } from 'date-fns';
export const Toast = ({ msg }) => <>{msg}</>;
export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};
export const generateUrl = (urlMode) => {
    const envMode = process.env.REACT_APP_ENV;
    let backendUrlPreFix, frontendUrlPreFix;
    let finalUrl, microsoftKey;
    if (envMode === 'prod') {
        microsoftKey = '9d01c478-e484-41d0-895e-cb5bab09b0c5';
        backendUrlPreFix = 'api.';
        frontendUrlPreFix = '';
    } else if (envMode === 'test' || envMode === 'preprod') {
        microsoftKey = '6749be51-045f-4805-a00d-3c323138b754';
        backendUrlPreFix = 'test.api.';
        frontendUrlPreFix = 'test.v1.';
    } else {
        microsoftKey = '6749be51-045f-4805-a00d-3c323138b754';
        backendUrlPreFix = 'dev.api.';
        frontendUrlPreFix = 'dev.v1.';
    }
    if (urlMode === 'microsoftKey') {
        finalUrl = microsoftKey;
    } else if (urlMode === 'account') {
        finalUrl = `https://${frontendUrlPreFix}account.incruiter.com`;
    } else if (urlMode === 'calendar') {
        finalUrl = `https://${frontendUrlPreFix}calendar.incruiter.com`;
    } else if (urlMode === 'website') {
        finalUrl = `https://${frontendUrlPreFix}incruiter.com`;
    } else {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/calendar-system/api/v1`;
    }
    return finalUrl;
};

export const encryptKey = 'y347t567t347InCrUiTeRb6t5b3487y5';
export const getLocalStorageItem = (key) => localStorage.getItem(key);
export const setLocalStorageItem = (key, value) => localStorage.setItem(key, value);
export const removeLocalStorageItem = (key) => localStorage.removeItem(key);

export const localStorageName = [
    { key: 'calendarId', value: 'calendarId' },
    { key: 'schedules', value: 'schedules' },
];

const commonLocalStorageDecryptCondition = (storageKey, isJson) => {
    let Key = storageKey;
    localStorageName
        .filter((itm) => itm.key === storageKey)
        .map((itm) => {
            Key = itm.value;
        });
    var data = '';
    if (getLocalStorageItem(Key)) {
        var bytes = CryptoJS.AES.decrypt(getLocalStorageItem(Key), encryptKey);
        data = bytes.toString(CryptoJS.enc.Utf8);
        if (data && isJson) {
            data = JSON.parse(data);
        }
    }
    return data;
};

export const localStorageEncrypt = (storageKey, data) => {
    let Key = storageKey;
    localStorageName
        .filter((itm) => itm.key === storageKey)
        .map((itm) => {
            Key = itm.value;
        });
    var value = CryptoJS.AES.encrypt(data, encryptKey).toString();
    setLocalStorageItem(Key, value);
};

export const localStorageDecrypt = (storageKey) => {
    return commonLocalStorageDecryptCondition(storageKey, false);
};

export const localStorageJsonParseDecrypt = (storageKey) => {
    return commonLocalStorageDecryptCondition(storageKey, true);
};

export const localStorageRemove = (storageKey) => {
    let Key = storageKey;
    localStorageName
        .filter((itm) => itm.key === storageKey)
        .map((itm) => {
            Key = itm.value;
        });
    localStorage.removeItem(Key);
};

export const convertTo12HourFormat = (time24) => {
    const date = parse(time24, 'HH:mm', new Date());
    const time12 = format(date, 'hh:mm a');
    return time12;
};
