import React, { useEffect } from 'react';
import Header from '../component/Header';
import Calendar from 'react-calendar';
import CandidateCalendarNew from '../component/CandidateCalenderNew/CandidateCalendarNew';
import backArrow from '../assets/images/arrow-back-blue.svg';
import google from '../assets/images/google-logo.svg';
import microsoft from '../assets/images/microsoft-logo.svg';
import { generateUrl } from '../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { panelistSlots } from '../redux/action';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';

const PanelistSlotView = () => {
    const today = new Date();
    const oneWeek = new Date();
    oneWeek.setDate(oneWeek.getDate() + 6);
    const handleBack = () => {
        window.location.href = `${generateUrl('account')}/create-panelist`;
    };
    const dispatch = useDispatch();
    let { mode, panelistId } = useParams();
    const viewPanelistData = useSelector((state) => state.CalendarR.viewPanelistData);
    const calendarSlotDateAndTime = viewPanelistData?.calendarSlotDateAndTime;

    const isDateWithSlots = (date) => {
        if (date) {
            const formattedDate = format(date, 'yyyy-MM-dd');
            return calendarSlotDateAndTime?.some((slot) => {
                if (slot && slot?.date) {
                    const slotDate = format(new Date(slot?.date), 'yyyy-MM-dd');
                    return slotDate === formattedDate && slot?.interviewSlots?.length > 0;
                } else return false;
            });
        }
    };

    const tileClassName = ({ date, view }) => {
        if (view === 'month' && isDateWithSlots(date)) {
            return 'date-with-slots';
        }
        return null;
    };
    useEffect(() => {
        if (panelistId && !viewPanelistData) dispatch(panelistSlots({ panelistId, mode }));
    }, [panelistId, viewPanelistData]);
    return (
        <div className="full-body">
            <Header />
            <div className="slots-main-body">
                <div className="back-body">
                    <div
                        className="d-flex align-items-center justify-content-start cursor-pointer"
                        onClick={handleBack}
                    >
                        <img src={backArrow} />
                        <span>Back</span>
                    </div>
                </div>
                <div className="slots-head">
                    <div className="name-text">{viewPanelistData?.name}</div>
                    <div className="available-text">Available Time Slots</div>
                    <div className="connected-info">
                        <img src={mode === 'Google' ? google : microsoft} />
                        Connected
                    </div>
                </div>
                <div className="slots-body slot-view">
                    <div className="rounded-10 d-center">
                        <div className="d-center">
                            <div className="d-flex flex-column">
                                <Calendar
                                    maxDetail="month"
                                    minDetail="month"
                                    minDate={today}
                                    maxDate={oneWeek}
                                    tileClassName={tileClassName}
                                />
                                <div className="d-center gap-30">
                                    <div className="f-9 fw-400 d-center">
                                        <div className="color-boxes blue me-2"></div> Available Slots
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <CandidateCalendarNew calendarSlotDateAndTime={calendarSlotDateAndTime} type="view" />
                </div>
            </div>
        </div>
    );
};

export default PanelistSlotView;
