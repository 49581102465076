import React, { memo } from 'react';

const Button = memo(({ type, className, onClick, isImage, image, text, disabled, style, imgStyle }) => {
    return (
        <>
            <button type={type} className={`${className}`} onClick={onClick} disabled={disabled} style={style}>
                {isImage && <img src={image} className="img-fluid" alt={text} style={imgStyle} />}

                {text}
            </button>
        </>
    );
});

export default Button;
