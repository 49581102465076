import React from 'react';
import Calendar from './CalendarList';
import './calendarStyle.css';

const CandidateCalendarNew = ({
    selectedDate,
    setSelectedDate,
    calendarSlotDateAndTime,
    selectedSlot,
    setSelectedSlot,
    type,
}) => {
    return (
        <Calendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            calendarSlotDateAndTime={calendarSlotDateAndTime}
            selectedSlot={selectedSlot}
            setSelectedSlot={setSelectedSlot}
            type={type}
        />
    );
};

export default CandidateCalendarNew;
