import { useState, useEffect, useLayoutEffect } from 'react';
import { format, addDays, subDays, differenceInCalendarDays } from 'date-fns';
import arrowBack from '../../assets/images/arrow_back.svg';
import { convertTo12HourFormat } from '../../utils/helpers';

const CalendarList = ({
    selectedDate,
    setSelectedDate,
    calendarSlotDateAndTime,
    selectedSlot,
    setSelectedSlot,
    type,
}) => {
    const today = new Date();
    const endDate = addDays(today, 7);
    const [startDate, setStartDate] = useState(today);
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const [listItems, setListItems] = useState(4);

    useLayoutEffect(() => {
        const handleResize = () => {
            setViewportWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (viewportWidth >= 1260) setListItems(5);
        else if (viewportWidth >= 1120 && viewportWidth < 1260) setListItems(4);
        else if (viewportWidth >= 990 && viewportWidth < 1120) setListItems(3);
        else setListItems(2);
    }, [viewportWidth]);

    const changeWeekHandle = (btnType) => {
        if (btnType === 'prev' && startDate > subDays(today, 4)) setStartDate(addDays(startDate, -listItems));
        if (btnType === 'next' && addDays(startDate, listItems) <= endDate) setStartDate(addDays(startDate, listItems));
    };

    useEffect(() => {
        const daysFromStart = differenceInCalendarDays(selectedDate, startDate);
        const daysFromToday = differenceInCalendarDays(selectedDate, today);

        if (daysFromStart < 0 || daysFromStart >= listItems) {
            const newStartDate =
                daysFromToday >= 0 ? addDays(today, Math.floor(daysFromToday / listItems) * listItems) : today;
            setStartDate(newStartDate);
        }
    }, [selectedDate]);

    const getInterviewSlots = (day) => {
        for (let i = 0; i < calendarSlotDateAndTime?.length; i++) {
            if (day && calendarSlotDateAndTime[i]?.date === format(day, 'yyyy-MM-dd')) {
                if (format(day, 'yyyy-MM-dd') === format(today, 'yyyy-MM-dd')) {
                    return calendarSlotDateAndTime[i]?.interviewSlots.filter(
                        (slot) => format(today, 'HH:mm') < slot?.start
                    );
                } else return calendarSlotDateAndTime[i]?.interviewSlots;
            }
        }
        return [];
    };

    const onTimeClick = (day, start, end) => {
        if (type !== 'view') {
            setSelectedDate(day);
            setSelectedSlot({
                day,
                start,
                end,
            });
        }
    };

    const days = [];
    const dateFormat = 'd';
    const dateFormat1 = 'EEE';
    let day = startDate;

    for (let i = 0; i < listItems; i++) {
        const formattedDate = format(day, dateFormat);
        const formattedDay = format(day, dateFormat1);
        const cloneDay = day;
        const interviewSlots = getInterviewSlots(day);
        days.push(
            <div className="col col-center d-flex flex-column align-items-center position-relative gap-20" key={i}>
                <div className="d-flex justify-content-center position-relative w-100">
                    {(i === 0 || i === listItems - 1) && (
                        <img
                            src={arrowBack}
                            className={`position-absolute ${
                                i === listItems - 1 ? 'rotate-180 right-0' : 'left-0'
                            } top-30`}
                            onClick={
                                i === listItems - 1 ? () => changeWeekHandle('next') : () => changeWeekHandle('prev')
                            }
                            style={{
                                visibility:
                                    (i === 0 && startDate <= today) ||
                                    (i === listItems - 1 && addDays(startDate, listItems) > endDate)
                                        ? 'hidden'
                                        : 'visible',
                            }}
                        />
                    )}
                    <div
                        className={`${
                            selectedDate &&
                            cloneDay &&
                            format(cloneDay, 'ccc dd MMM yy') === format(selectedDate, 'ccc dd MMM yy')
                                ? 'active'
                                : ''
                        } cursor-default pad-5`}
                    >
                        {formattedDay}
                        <br />
                        <span className="f-20">{formattedDate}</span>
                    </div>
                </div>
                <div className="all-times">
                    {interviewSlots.map((slot, index) => (
                        <div
                            className={`time-div ${type === 'view' ? 'cursor-default' : ''} ${selectedSlot?.day && day && format(selectedSlot?.day, 'ccc dd MMM yy') === format(day, 'ccc dd MMM yy') && selectedSlot?.start === slot?.start && selectedSlot?.end === slot?.end ? 'active-time' : ''}`}
                            key={index}
                            onClick={() => onTimeClick(cloneDay, slot?.start, slot?.end)}
                        >
                            {`${slot?.start ? convertTo12HourFormat(slot?.start) : ''}-${slot?.end ? convertTo12HourFormat(slot?.end) : ''}`}
                        </div>
                    ))}
                    {Array.from({ length: 5 - interviewSlots.length }, (_, index) => (
                        <div className="time-div-empty" key={interviewSlots.length + index}>
                            -
                        </div>
                    ))}
                </div>
            </div>
        );
        day = addDays(day, 1);
    }

    return (
        <div className="calendar-week">
            <div className="days row">{days}</div>
        </div>
    );
};

export default CalendarList;
