import {
    GET_INTERVIEWER_CALENDAR,
    POST_INTERVIEWER_CALENDAR,
    VERIFY_CALENDAR_REQUEST,
    POST_CANDIDATE_CALENDAR,
    GET_INTERVIEWER_CALENDAR_SUCCESS,
    POST_INTERVIEWER_CALENDAR_SUCCESS,
    VERIFY_CALENDAR_REQUEST_SUCCESS,
    POST_CANDIDATE_CALENDAR_SUCCESS,
    GET_INTERVIEWER_CALENDAR_FAILURE,
    POST_INTERVIEWER_CALENDAR_FAILURE,
    VERIFY_CALENDAR_REQUEST_FAILURE,
    POST_CANDIDATE_CALENDAR_FAILURE,
    GET_RESCHEDULE_DATA,
    GET_RESCHEDULE_DATA_SUCCESS,
    GET_RESCHEDULE_DATA_FAILURE,
    POST_RESCHEDULE_DATA,
    POST_RESCHEDULE_DATA_SUCCESS,
    POST_RESCHEDULE_DATA_FAILURE,
    CALENDAR_CONNECT,
    CALENDAR_CONNECT_SUCCESS,
    CALENDAR_CONNECT_FAILURE,
    CHECK_CALENDAR_CONNECTION,
    CHECK_CALENDAR_CONNECTION_FAILURE,
    CHECK_CALENDAR_CONNECTION_SUCCESS,
    CANCEL_CALENDAR_INTERVIEW,
    CANCEL_CALENDAR_INTERVIEW_SUCCESS,
    CANCEL_CALENDAR_INTERVIEW_FAILURE,
    RESCHEDULE_CALENDAR_INTERVIEW,
    RESCHEDULE_CALENDAR_INTERVIEW_SUCCESS,
    RESCHEDULE_CALENDAR_INTERVIEW_FAILURE,
    PANELIST_SLOTS,
    PANELIST_SLOTS_SUCCESS,
    PANELIST_SLOTS_FAILURE,
    VERIFY_NEW_CALENDAR_REQUEST,
    VERIFY_NEW_CALENDAR_REQUEST_SUCCESS,
    VERIFY_NEW_CALENDAR_REQUEST_FAILURE,
} from './types';

export const getInterviewerCalendar = (payload) => ({
    type: GET_INTERVIEWER_CALENDAR,
    payload,
});
export const getInterviewerCalendarSuccess = (payload) => ({
    type: GET_INTERVIEWER_CALENDAR_SUCCESS,
    payload,
});
export const getInterviewerCalendarFailure = () => ({
    type: GET_INTERVIEWER_CALENDAR_FAILURE,
});

export const postInterviewerCalendar = (payload) => ({
    type: POST_INTERVIEWER_CALENDAR,
    payload,
});
export const postInterviewerCalendarSuccess = (payload) => ({
    type: POST_INTERVIEWER_CALENDAR_SUCCESS,
    payload,
});
export const postInterviewerCalendarFailure = () => ({
    type: POST_INTERVIEWER_CALENDAR_FAILURE,
});

export const verifyCalendarRequest = (payload) => ({
    type: VERIFY_CALENDAR_REQUEST,
    payload,
});
export const verifyCalendarRequestSuccess = (payload) => ({
    type: VERIFY_CALENDAR_REQUEST_SUCCESS,
    payload,
});
export const verifyCalendarRequestFailure = () => ({
    type: VERIFY_CALENDAR_REQUEST_FAILURE,
});

export const verifyNewCalendarRequest = (payload) => ({
    type: VERIFY_NEW_CALENDAR_REQUEST,
    payload,
});
export const verifyNewCalendarRequestSuccess = (payload) => ({
    type: VERIFY_NEW_CALENDAR_REQUEST_SUCCESS,
    payload,
});
export const verifyNewCalendarRequestFailure = () => ({
    type: VERIFY_NEW_CALENDAR_REQUEST_FAILURE,
});

export const postCandidateCalendar = (payload) => ({
    type: POST_CANDIDATE_CALENDAR,
    payload,
});
export const postCandidateCalendarSuccess = (payload) => ({
    type: POST_CANDIDATE_CALENDAR_SUCCESS,
    payload,
});
export const postCandidateCalendarFailure = () => ({
    type: POST_CANDIDATE_CALENDAR_FAILURE,
});
export const getRescheduleData = (payload) => ({
    type: GET_RESCHEDULE_DATA,
    payload,
});
export const getRescheduleDataSuccess = (payload) => ({
    type: GET_RESCHEDULE_DATA_SUCCESS,
    payload,
});
export const getRescheduleDataFailure = () => ({
    type: GET_RESCHEDULE_DATA_FAILURE,
});
export const postRescheduleData = (payload) => ({
    type: POST_RESCHEDULE_DATA,
    payload,
});
export const postRescheduleDataSuccess = () => ({
    type: POST_RESCHEDULE_DATA_SUCCESS,
});
export const postRescheduleDataFailure = () => ({
    type: POST_RESCHEDULE_DATA_FAILURE,
});
export const calendarConnect = (payload) => ({
    type: CALENDAR_CONNECT,
    payload,
});
export const calendarConnectSuccess = (payload) => ({
    type: CALENDAR_CONNECT_SUCCESS,
    payload,
});
export const calendarConnectFailure = () => ({
    type: CALENDAR_CONNECT_FAILURE,
});
export const checkCalendarConnection = (payload) => ({
    type: CHECK_CALENDAR_CONNECTION,
    payload,
});
export const checkCalendarConnectionSuccess = (payload) => ({
    type: CHECK_CALENDAR_CONNECTION_SUCCESS,
    payload,
});
export const checkCalendarConnectionFailure = () => ({
    type: CHECK_CALENDAR_CONNECTION_FAILURE,
});
export const cancelCalendarInterview = (payload) => ({
    type: CANCEL_CALENDAR_INTERVIEW,
    payload,
});
export const cancelCalendarInterviewSuccess = () => ({
    type: CANCEL_CALENDAR_INTERVIEW_SUCCESS,
});
export const cancelCalendarInterviewFailure = () => ({
    type: CANCEL_CALENDAR_INTERVIEW_FAILURE,
});
export const rescheduleCalendarInterview = (payload) => ({
    type: RESCHEDULE_CALENDAR_INTERVIEW,
    payload,
});
export const rescheduleCalendarInterviewSuccess = () => ({
    type: RESCHEDULE_CALENDAR_INTERVIEW_SUCCESS,
});
export const rescheduleCalendarInterviewFailure = () => ({
    type: RESCHEDULE_CALENDAR_INTERVIEW_FAILURE,
});
export const panelistSlots = (payload) => ({
    type: PANELIST_SLOTS,
    payload,
});
export const panelistSlotsSuccess = (payload) => ({
    type: PANELIST_SLOTS_SUCCESS,
    payload,
});
export const panelistSlotsFailure = () => ({
    type: PANELIST_SLOTS_FAILURE,
});
