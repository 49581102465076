import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { verifyCalendarRequest, verifyNewCalendarRequest } from '../redux/action';
import CandidateSlots from '../component/CandidateCalenderNew/CandidateSlots';

const CandidateCalendarSync = ({ type }) => {
    const dispatch = useDispatch();
    let { calendarId, interviewId } = useParams();
    const verifyCalendar = useSelector((state) => state.CalendarR.verifyCalendarRequest);
    const verifyNewCalendar = useSelector((state) => state.CalendarR.verifyNewCalendarRequest);
    const loading = useSelector((state) => state.CalendarR.loading);
    useEffect(() => {
        if (calendarId && !verifyCalendar && type !== 'reschedule') dispatch(verifyCalendarRequest({ calendarId }));
        if (calendarId && !verifyNewCalendar && type == 'reschedule')
            dispatch(verifyNewCalendarRequest({ calendarId }));
    }, [calendarId, verifyCalendar, verifyNewCalendar]);

    return (
        <CandidateSlots
            verifyCalendar={type === 'reschedule' ? verifyNewCalendar : verifyCalendar}
            interviewId={interviewId}
            type={type}
            loading={loading}
        />
    );
};

export default CandidateCalendarSync;
