import React from 'react';

const Footer = ({ Content }) => {
    return (
        <div className="footer">
            <Content />
        </div>
    );
};

export default Footer;
