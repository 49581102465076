import React, { useState } from 'react';
import blackClose from '../assets/images/blackClose.svg';

const Popup = ({
    isClearPopup,
    firstButtonClass,
    setIsClearPopup,
    popupImg,
    header,
    content,
    firstBtnContent,
    secondBtnContent,
    handleFirstButtonClick,
    handleSecondButtonClick,
}) => {
    return (
        <div className="reset-popup">
            <div className="reset-clear-popup rounded position-relative">
                <img
                    className="close-btn cursor-pointer"
                    src={blackClose}
                    alt="close-button"
                    onClick={() => {
                        setIsClearPopup(false);
                    }}
                ></img>
                {popupImg && (
                    <div className="d-flex justify-content-center">
                        <img src={popupImg} alt="featured-icon"></img>
                    </div>
                )}
                {header && <div className="f-sem-bol f-18 mar-top-16 text-center">{header}</div>}
                {content && <div className="f-14 text-center">{content}</div>}
                <div
                    className={`reset-popup-btn-container mar-top-32 d-flex ${!secondBtnContent ? 'justify-content-center align-items-center' : ''}`}
                >
                    {handleFirstButtonClick && (
                        <button
                            className={`cpb-white cpb rounded f-16 ${firstButtonClass ? firstButtonClass : ''}`}
                            onClick={handleFirstButtonClick}
                        >
                            <span className="f-16">{firstBtnContent}</span>
                        </button>
                    )}
                    {handleSecondButtonClick && (
                        <button className="cpb-red cpb rounded f-16" onClick={handleSecondButtonClick}>
                            <span className="f-16">{secondBtnContent}</span>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Popup;
