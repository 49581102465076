import React from 'react';
import thankYou from '../../assets/images/thankYou.svg';
import tick from '../../assets/images/green-tick.svg';

const CandidateThankYou = ({ companyName, date, startTime, type }) => {
    return (
        <div className="thank-main-parent">
            <img src={thankYou} />
            <div className="thank-main-content">
                {type !== 'cancel' && <div className="thank-you">Thank You!</div>}
                <div className="thank-you-body">
                    <img src={tick} style={{ width: 24, marginRight: 10 }} />
                    {type === 'panelist'
                        ? 'Thank you for syncing your calendar in our system!'
                        : type === 'cancel'
                          ? 'Your interview has been cancelled.'
                          : type === 'reschedule'
                            ? `Your interview has been rescheduled, and the invite has been sent.`
                            : `Thank you for choosing an interview slot with ${companyName}.`}
                </div>
                {type !== 'cancel' && (
                    <div>
                        {type === 'panelist'
                            ? 'Your availability has been shared with the recruiter'
                            : type === 'reschedule'
                              ? `The updated interview schedule date is ${date} at ${startTime}.`
                              : `Your interview is scheduled for ${date} at ${startTime}.`}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CandidateThankYou;
