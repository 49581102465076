import { addMinutes, format, parse } from 'date-fns';
import React from 'react';
import { convertTo12HourFormat } from '../../utils/helpers';

const ReschedulePopUp = ({ setIsClearPopup, handleReschedule, date, time, duration }) => {
    const addMinutesToTime = (time24hr, durationMinutes) => {
        if (time24hr && durationMinutes) {
            const date = parse(time24hr, 'HH:mm', new Date());
            const endDate = addMinutes(date, durationMinutes);
            return format(endDate, 'HH:mm');
        }
    };

    return (
        <div className="reset-popup">
            <div className="reset-clear-popup rounded d-flex">
                <div className="f-sem-bol f-18 text-center">Interview Reschedule</div>
                <div className="popup-line"></div>
                <div className="f-14 text-center">Are you sure you want to reschedule this interview?</div>
                <div className="f-sem-bol f-14 text-center">Current schedule Date:</div>
                <div className="f-sem-bol f-14 text-center">{`${convertTo12HourFormat(time)}-${convertTo12HourFormat(
                    addMinutesToTime(time, Number(duration))
                )} ${format(date, 'MMMM d, yyyy')}`}</div>
                <div className="reset-popup-btn-container mar-top-32 d-flex">
                    <button
                        className="cpb-blue cpb rounded f-16"
                        onClick={() => {
                            setIsClearPopup(false);
                            handleReschedule();
                        }}
                    >
                        <span className="f-16">Yes</span>
                    </button>
                    <button className="cpb-white cpb rounded f-16" onClick={() => setIsClearPopup(false)}>
                        <span className="f-16">No</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ReschedulePopUp;
