import React, { useState, useEffect } from 'react';
import Button from '../component/Button';
import GMButton from '../component/GoogleMicrosoftButton';
import moment from 'moment';
import blackClose from '../assets/images/blackClose.svg';
import featuredimg from '../assets/images/Featuredpopup.svg';
import { selectDays, officeTimings, interviewDuration, monFriSelect, satSunSelect } from '../utils/constant';
import {
    capitalize,
    generateUrl,
    localStorageEncrypt,
    localStorageJsonParseDecrypt,
    localStorageRemove,
} from '../utils/helpers';
import microsoftLogo from '../assets/images/microsoft-logo.svg';
import googleLogo from '../assets/images/google-logo.svg';
import tickLogo from '../assets/images/tick-icon.svg';
import { toast } from 'react-toastify';
import { Toast } from '../utils/helpers';
import Header from '../component/Header';
import { useDispatch, useSelector } from 'react-redux';
import {
    checkCalendarConnection,
    getInterviewerCalendar,
    postInterviewerCalendar,
    verifyCalendarRequest,
} from '../redux/action';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CandidateThankYou from '../component/CandidateCalenderNew/CandidateThankYou';
import orImg from '../assets/images/orImg.svg';
import tickImg from '../assets/images/tickImage.svg';
import Loader from '../component/Loader';
import ReactSelect from 'react-select';
import Popup from '../component/Popup';
const InterviewerCalendarSync = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    let { calendarId } = useParams();
    const verifyCalendar = useSelector((state) => state.CalendarR.verifyCalendarRequest);
    const interviewerData = useSelector((state) => state.CalendarR.interviewerCalendarData);
    const calendarConnected = useSelector((state) => state.CalendarR.calendarConnected);
    const loading = useSelector((state) => state.CalendarR.loading);
    const [isClearPopup, setIsClearPopup] = useState(false);
    const [isEmailMismatch, setIsEmailMismatch] = useState(false);
    const [isSavePopup, setIsSavePopup] = useState(false);
    const [schedules, setSchedules] = useState(
        localStorageJsonParseDecrypt('schedules') || [
            {
                selectedDays: 'All Days',
                selectedStartTime: '00:00',
                selectedEndTime: '00:00',
                interviewTime: '45 mins',
                error: '',
                endTimeList: officeTimings,
            },
        ]
    );
    const [interviewerId, setInterviewerId] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);
    const [thankYou, setThankYou] = useState(false);
    const [slotsForm, setSlotsForm] = useState([
        { slotDay: 'Mon', availableSlots: [] },
        { slotDay: 'Tue', availableSlots: [] },
        { slotDay: 'Wed', availableSlots: [] },
        { slotDay: 'Thu', availableSlots: [] },
        { slotDay: 'Fri', availableSlots: [] },
        { slotDay: 'Sat', availableSlots: [] },
        { slotDay: 'Sun', availableSlots: [] },
    ]);

    const postConnectionStatus = location.state;

    useEffect(() => {
        if (postConnectionStatus) {
            if (postConnectionStatus === 'connected') setIsSavePopup(true);
            if (postConnectionStatus === 'connectionError') setIsEmailMismatch(true);
        }
    }, [postConnectionStatus]);

    useEffect(() => {
        localStorageEncrypt('calendarId', calendarId);
    }, [calendarId]);

    useEffect(() => {
        if (localStorageJsonParseDecrypt('schedules')) {
            setSchedules(localStorageJsonParseDecrypt('schedules'));
            localStorageRemove('schedules');
        }
    }, []);

    useEffect(() => {
        if (calendarId && !verifyCalendar) {
            dispatch(verifyCalendarRequest({ calendarId }));
        }
    }, []);

    useEffect(() => {
        if (
            interviewerData?.panelistCalendarSlot?.schedule &&
            interviewerData?.panelistCalendarSlot?.schedule?.length > 0
        ) {
            setSchedules(interviewerData?.panelistCalendarSlot?.schedule);
        }
    }, [interviewerData]);

    useEffect(() => {
        if (calendarId && verifyCalendar) {
            let intId = verifyCalendar?.data?.requestId?.referenceId;
            setInterviewerId(intId);
            if (intId) dispatch(getInterviewerCalendar({ intId }));
        }
    }, [dispatch, calendarId, verifyCalendar]);

    const redirectUri = `${generateUrl('calendar')}/sync-calendar`;
    const clientIdGoogle = '253810983389-ctr8b3antu5j2smqe3hflp03lsqp9vja.apps.googleusercontent.com';
    const clientIdMicrosoft = generateUrl('microsoftKey');
    const onConnect = (service) => {
        const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/userinfo.email&include_granted_scopes=true&response_type=code&state=unique_value&redirect_uri=${redirectUri}&client_id=${clientIdGoogle}&access_type=offline&prompt=consent`;
        const microsoftAuthUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientIdMicrosoft}&response_type=code&redirect_uri=${redirectUri}&response_mode=query&scope=calendars.readwrite offline_access User.Read&prompt=consent`;
        const authUrl = service === 'Google' ? googleAuthUrl : microsoftAuthUrl;
        localStorageEncrypt('schedules', JSON.stringify(schedules));
        window.location.href = authUrl;
    };

    const handleSlotsSubmit = (reset) => {
        const tempSlots = slotsForm;
        const pushToSlots = (day, schedule) => {
            const slotDuration = Number(schedule.interviewTime.substring(0, 2));
            tempSlots[day].availableSlots.push({
                start: schedule?.selectedStartTime,
                end: schedule?.selectedEndTime,
                slotDuration,
            });
        };
        schedules.forEach((schedule) => {
            switch (schedule.selectedDays) {
                case 'All Days':
                    pushToSlots(0, schedule);
                    pushToSlots(1, schedule);
                    pushToSlots(2, schedule);
                    pushToSlots(3, schedule);
                    pushToSlots(4, schedule);
                    pushToSlots(5, schedule);
                    pushToSlots(6, schedule);
                    break;
                case 'Mondays - Fridays':
                    pushToSlots(0, schedule);
                    pushToSlots(1, schedule);
                    pushToSlots(2, schedule);
                    pushToSlots(3, schedule);
                    pushToSlots(4, schedule);
                    break;
                case 'Saturdays - Sundays':
                    pushToSlots(5, schedule);
                    pushToSlots(6, schedule);
                    break;
                case 'Mondays':
                    pushToSlots(0, schedule);
                    break;
                case 'Tuesdays':
                    pushToSlots(1, schedule);
                    break;
                case 'Wednesdays':
                    pushToSlots(2, schedule);
                    break;
                case 'Thursdays':
                    pushToSlots(3, schedule);
                    break;
                case 'Fridays':
                    pushToSlots(4, schedule);
                    break;
                case 'Saturdays':
                    pushToSlots(5, schedule);
                    break;
                case 'Sundays':
                    pushToSlots(6, schedule);
                    break;
            }
        });
        setSlotsForm(tempSlots);
        const resetSlots = [
            { slotDay: 'Mon', availableSlots: [] },
            { slotDay: 'Tue', availableSlots: [] },
            { slotDay: 'Wed', availableSlots: [] },
            { slotDay: 'Thu', availableSlots: [] },
            { slotDay: 'Fri', availableSlots: [] },
            { slotDay: 'Sat', availableSlots: [] },
            { slotDay: 'Sun', availableSlots: [] },
        ];

        const resetSchedule = [
            {
                selectedDays: 'All Days',
                selectedStartTime: '00:00',
                selectedEndTime: '00:00',
                interviewTime: '45 mins',
                error: '',
                endTimeList: officeTimings,
            },
        ];
        const data = {
            panelistRequestId: verifyCalendar?.data?._id,
            panelistCalendarId: verifyCalendar?.data?.requestId?._id,
            slotDateAndTime: reset ? resetSlots : tempSlots,
            schedule: schedules,
        };
        if (reset) {
            data.reset = true;
            delete data.schedule;
        }
        dispatch(
            postInterviewerCalendar({
                data,
                callback: () => {
                    !reset && setThankYou(true);
                    reset &&
                        setSchedules([
                            {
                                selectedDays: 'All Days',
                                selectedStartTime: '00:00',
                                selectedEndTime: '00:00',
                                interviewTime: '45 mins',
                                error: '',
                                endTimeList: officeTimings,
                            },
                        ]);
                    if (reset) dispatch(checkCalendarConnection({ user: interviewerId }));
                },
            })
        );
    };

    useEffect(() => {
        if (interviewerId) dispatch(checkCalendarConnection({ user: interviewerId }));
    }, [interviewerId]);

    useEffect(() => {
        schedules.forEach((schedule, index) => {
            if (schedule?.selectedStartTime !== '00:00') {
                const startTimeList = officeTimings;
                const startIndex = startTimeList.findIndex((time) => time === schedule?.selectedStartTime);
                const endTimeList = startTimeList.slice(startIndex + 1);
                setSchedules((prevSchedules) => prevSchedules.map((s, i) => (i === index ? { ...s, endTimeList } : s)));
            }
        });
    }, []);

    const handleChange = (index, field, value) => {
        const updatedSchedules = schedules.map((schedule, i) => {
            if (i === index) {
                const updatedSchedule = { ...schedule, [field]: value, error: '' };
                if (field === 'selectedStartTime') {
                    const startTimeList = officeTimings;
                    const startIndex = startTimeList.findIndex((time) => time === value);
                    updatedSchedule.endTimeList = startTimeList.slice(startIndex + 1);
                    updatedSchedule.selectedEndTime = '00:00';
                }
                if (field === 'selectedEndTime' && value !== '00:00') {
                    updatedSchedule.error = '';
                }
                return updatedSchedule;
            }
            return schedule;
        });
        setSchedules(updatedSchedules);

        if (
            field === 'selectedStartTime' ||
            field === 'selectedEndTime' ||
            field === 'selectedDays' ||
            field === 'interviewTime'
        ) {
            checkOverlap(updatedSchedules);
        }
    };

    const handleRemoveSchedule = (index) => {
        if (schedules.length === 1) {
            // Clear the data if there's only one row
            const updatedSchedules = schedules.map((schedule, i) => {
                if (i === index) {
                    return {
                        ...schedule,
                        selectedDays: 'All Days',
                        selectedStartTime: '00:00',
                        selectedEndTime: '00:00',
                        interviewTime: '45 mins',
                        error: '',
                        endTimeList: officeTimings,
                    };
                }
                return schedule;
            });
            setSchedules(updatedSchedules);
        } else {
            const updatedSchedules = schedules.filter((_, i) => i !== index);
            setSchedules(updatedSchedules);
        }
    };

    const canAddDay = () => {
        const lastSchedule = schedules[schedules.length - 1];
        return lastSchedule?.selectedStartTime !== '00:00' && lastSchedule?.selectedEndTime !== '00:00';
    };

    const handleAddDay = () => {
        setSchedules([
            ...schedules,
            {
                selectedDays: 'All Days',
                selectedStartTime: '00:00',
                selectedEndTime: '00:00',
                interviewTime: '45 mins',
                error: '',
                endTimeList: officeTimings,
            },
        ]);
    };

    const getMinutesDifference = (time1, time2) => {
        const [hours1, minutes1] = time1.split(':').map(Number);
        const [hours2, minutes2] = time2.split(':').map(Number);

        const date1 = new Date(2000, 0, 1, hours1, minutes1);
        const date2 = new Date(2000, 0, 1, hours2, minutes2);

        const differenceInMilliseconds = Math.abs(date2 - date1);

        const differenceInMinutes = Math.floor(differenceInMilliseconds / 60000);

        return differenceInMinutes;
    };

    const checkOverlap = (updatedSchedules) => {
        updatedSchedules.forEach((schedule, index) => {
            schedule.error = '';
        });

        updatedSchedules.forEach((schedule, index) => {
            if (schedule?.selectedEndTime !== '00:00') {
                const days = getSelectedDays(schedule.selectedDays);
                const startTime = moment(schedule?.selectedStartTime, 'HH:mm');
                const endTime = moment(schedule?.selectedEndTime, 'HH:mm');
                const mins = getMinutesDifference(schedule?.selectedStartTime, schedule.selectedEndTime);
                const interviewTime = Number(schedule?.interviewTime.substring(0, 2));
                if (Number(mins) < Number(interviewTime)) {
                    updatedSchedules[index].error = 'selected time slot less than interview duration';
                    updatedSchedules[index].selectedStartTime = '00:00';
                    updatedSchedules[index].selectedEndTime = '00:00';
                }

                updatedSchedules.forEach((otherSchedule, otherIndex) => {
                    if (index !== otherIndex && otherSchedule?.selectedEndTime !== '00:00') {
                        const otherDays = getSelectedDays(otherSchedule?.selectedDays);
                        const otherStartTime = moment(otherSchedule?.selectedStartTime, 'HH:mm');
                        const otherEndTime = moment(otherSchedule?.selectedEndTime, 'HH:mm');

                        const daysOverlap = days.some((day) => otherDays.includes(day));
                        const timeOverlap = startTime.isBefore(otherEndTime) && otherStartTime.isBefore(endTime);

                        if (daysOverlap && timeOverlap) {
                            updatedSchedules[otherIndex].error = "Schedule can't overlap. Please edit your schedule.";
                            updatedSchedules[otherIndex].selectedStartTime = '00:00';
                            updatedSchedules[otherIndex].selectedEndTime = '00:00';
                        }
                    }
                });
            }
        });

        setSchedules([...updatedSchedules]);
    };

    const getSelectedDays = (selectedDays) => {
        if (selectedDays === 'All Days') {
            return selectDays;
        } else if (selectedDays === 'Mondays - Fridays') {
            return monFriSelect;
        } else if (selectedDays === 'Saturdays - Sundays') {
            return satSunSelect;
        } else {
            return selectedDays.split(', ');
        }
    };

    const handlePanelistSlots = () => {
        let errors = [];

        schedules.forEach((schedule, index) => {
            if (schedule?.selectedStartTime === '00:00' || schedule?.selectedEndTime === '00:00' || schedule?.error) {
                errors.push(index);
            }
        });

        if (errors.length > 0) {
            setValidationErrors(errors);
        } else {
            setValidationErrors([]);
            if (!calendarConnected) {
                toast.error(<Toast msg={'Please connect your calendar'} />, {
                    toastId: 'Please connect your calendar',
                });
            } else {
                handleSlotsSubmit();
            }
        }
    };

    const selectStyles = {
        control: (base, state) => ({
            ...base,
            minWidth: 71,
            width: '100%',
            fontSize: 12,
            border: '0.3px solid black', // Apply a 0.3px black border
            boxShadow: 'none', // Remove focus shadow effect
            '&:hover': {
                border: '0.3px solid black', // Ensure border stays the same on hover
            },
        }),
        option: (base, state) => ({
            ...base,
            fontSize: 10,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }),
        menu: (base) => ({
            ...base,
            borderRadius: 4,
            fontSize: 10,
        }),
    };

    return (
        <div className="full-body">
            {loading && <Loader />}
            <Header isMobileCenter />
            {thankYou ? (
                <CandidateThankYou type="panelist" />
            ) : (
                <div className="panelist-slot-schedule">
                    <div className="title-box panelist-slots-title-center">
                        <span className="f-text-24 f-bol ps-3 calendar-title">
                            {capitalize(interviewerData?.name)} - Set Your Availability
                        </span>
                    </div>
                    <span className="f-text-18 f-bol ps-3 calendar-title">
                        Sync your calendar to share your interview availability
                    </span>
                    <div className="calendar-slots-container">
                        <div className="calendar">
                            <div className="calendar-container-new rounded-15">
                                <div className="">
                                    <div className="f-bol text-center">
                                        Add your schedule
                                        <span className="asterisk">*</span>
                                        <div>
                                            <span className="f-10">
                                                Your slots will be available for chosen days and time
                                            </span>
                                        </div>
                                    </div>
                                    <hr className="hr-style" />
                                    <div className="rows-div">
                                        {schedules.map((schedule, index) => (
                                            <div
                                                key={index}
                                                className="d-flex align-items-center mtop-10 flex-wrap-600 days-timing-dur position-relative"
                                            >
                                                <div className="d-flex flex-column days">
                                                    {index === 0 && (
                                                        <label className="f-bol f-12 mtop-10">
                                                            Select Days<span className="asterisk">*</span>
                                                        </label>
                                                    )}
                                                    <ReactSelect
                                                        styles={selectStyles}
                                                        options={selectDays.map((day) => ({ value: day, label: day }))}
                                                        value={{
                                                            value: schedule.selectedDays,
                                                            label: schedule.selectedDays,
                                                        }}
                                                        onChange={(selectedOption) =>
                                                            handleChange(index, 'selectedDays', selectedOption.value)
                                                        }
                                                        className="f-12 f-bol rounded-15 p-0 select-days-drop cursor-pointer days"
                                                        menuPosition="absolute"
                                                        menuPortalTarget={document.body}
                                                        isSearchable={false}
                                                    />
                                                </div>
                                                <div className="d-flex flex-column timing">
                                                    {index === 0 && (
                                                        <label className="f-bol f-12 mtop-10">
                                                            Interview Availability<span className="asterisk">*</span>
                                                        </label>
                                                    )}
                                                    <div className="d-flex">
                                                        <ReactSelect
                                                            styles={{
                                                                ...selectStyles,
                                                                indicatorsContainer: (base) => ({
                                                                    ...base,
                                                                    padding: 0,
                                                                }),
                                                                dropdownIndicator: (base) => ({
                                                                    ...base,
                                                                    padding: 0,
                                                                    '& svg': {
                                                                        width: 12,
                                                                        height: 12,
                                                                    },
                                                                }),
                                                                control: (base) => ({
                                                                    ...base,
                                                                    border:
                                                                        validationErrors.includes(index) &&
                                                                        schedule.selectedStartTime === '00:00'
                                                                            ? '0.3px solid red'
                                                                            : '0.3px solid black', // Apply a 0.3px black border
                                                                    '&:hover': {
                                                                        border:
                                                                            validationErrors.includes(index) &&
                                                                            schedule.selectedStartTime === '00:00'
                                                                                ? '0.3px solid red'
                                                                                : '0.3px solid black', // Ensure border stays the same on hover
                                                                    },
                                                                }),
                                                                control: (base, state) => ({
                                                                    ...base,
                                                                    minWidth: 71,
                                                                    width: '100%',
                                                                    fontSize: 12,
                                                                    border:
                                                                        validationErrors.includes(index) &&
                                                                        (schedule.selectedStartTime === '00:00' ||
                                                                            (schedule.selectedStartTime !== '00:00' &&
                                                                                schedule.selectedEndTime === '00:00'))
                                                                            ? '0.3px solid red'
                                                                            : '0.3px solid black', // Apply a 0.3px black border
                                                                    boxShadow: 'none', // Remove focus shadow effect
                                                                    '&:hover': {
                                                                        border:
                                                                            validationErrors.includes(index) &&
                                                                            (schedule.selectedStartTime === '00:00' ||
                                                                                (schedule.selectedStartTime !==
                                                                                    '00:00' &&
                                                                                    schedule.selectedEndTime ===
                                                                                        '00:00'))
                                                                                ? '0.3px solid red'
                                                                                : '0.3px solid black', // Ensure border stays the same on hover
                                                                    },
                                                                }),
                                                            }}
                                                            options={officeTimings.map((time) => ({
                                                                value: time,
                                                                label: time,
                                                            }))}
                                                            value={{
                                                                value: schedule.selectedStartTime,
                                                                label: schedule.selectedStartTime,
                                                            }}
                                                            onChange={(selectedOption) =>
                                                                handleChange(
                                                                    index,
                                                                    'selectedStartTime',
                                                                    selectedOption.value
                                                                )
                                                            }
                                                            className={`f-12 f-bol rounded-15 p-0 select-office-drop d-flex cursor-pointer timing`}
                                                            menuPosition="absolute"
                                                            menuPortalTarget={document.body}
                                                            isSearchable={false}
                                                        />
                                                        <span className="text-to">to</span>
                                                        <ReactSelect
                                                            styles={{
                                                                ...selectStyles,
                                                                indicatorsContainer: (base) => ({
                                                                    ...base,
                                                                    padding: 0,
                                                                }),
                                                                dropdownIndicator: (base) => ({
                                                                    ...base,
                                                                    padding: 0,
                                                                    '& svg': {
                                                                        width: 12,
                                                                        height: 12,
                                                                    },
                                                                }),
                                                                control: (base, state) => ({
                                                                    ...base,
                                                                    minWidth: 71,
                                                                    width: '100%',
                                                                    fontSize: 12,
                                                                    border:
                                                                        validationErrors.includes(index) &&
                                                                        (schedule.selectedStartTime === '00:00' ||
                                                                            (schedule.selectedStartTime !== '00:00' &&
                                                                                schedule.selectedEndTime === '00:00'))
                                                                            ? '0.3px solid red'
                                                                            : '0.3px solid black', // Apply a 0.3px black border
                                                                    boxShadow: 'none', // Remove focus shadow effect
                                                                    '&:hover': {
                                                                        border:
                                                                            validationErrors.includes(index) &&
                                                                            (schedule.selectedStartTime === '00:00' ||
                                                                                (schedule.selectedStartTime !==
                                                                                    '00:00' &&
                                                                                    schedule.selectedEndTime ===
                                                                                        '00:00'))
                                                                                ? '0.3px solid red'
                                                                                : '0.3px solid black', // Ensure border stays the same on hover
                                                                    },
                                                                }),
                                                            }}
                                                            options={
                                                                schedule.endTimeList
                                                                    ? schedule.endTimeList.map((time) => ({
                                                                          value: time,
                                                                          label: time,
                                                                      }))
                                                                    : []
                                                            }
                                                            value={{
                                                                value: schedule.selectedEndTime,
                                                                label: schedule.selectedEndTime,
                                                            }}
                                                            onChange={(selectedOption) =>
                                                                handleChange(
                                                                    index,
                                                                    'selectedEndTime',
                                                                    selectedOption.value
                                                                )
                                                            }
                                                            className={`f-12 f-bol rounded-15 p-0 select-office-drop-end d-flex cursor-pointer timing`}
                                                            isDisabled={schedule.selectedStartTime === '00:00'}
                                                            menuPosition="absolute"
                                                            menuPortalTarget={document.body}
                                                            isSearchable={false}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column duration">
                                                    {index === 0 && (
                                                        <label className="f-bol f-12 mtop-10">
                                                            Interview Duration<span className="asterisk">*</span>
                                                        </label>
                                                    )}
                                                    <ReactSelect
                                                        styles={selectStyles}
                                                        options={interviewDuration.map((duration) => ({
                                                            value: duration,
                                                            label: duration,
                                                        }))}
                                                        value={{
                                                            value: schedule.interviewTime,
                                                            label: schedule.interviewTime,
                                                        }}
                                                        onChange={(selectedOption) =>
                                                            handleChange(index, 'interviewTime', selectedOption.value)
                                                        }
                                                        className="f-12 f-bol rounded-15 p-0 select-duration-drop cursor-pointer duration"
                                                        menuPosition="absolute"
                                                        menuPortalTarget={document.body}
                                                        isSearchable={false}
                                                    />
                                                </div>
                                                <div
                                                    className={`close-review-tab ${index === 0 ? 'mar-7' : 'mtop-1'}`}
                                                    onClick={() => {
                                                        handleRemoveSchedule(index);
                                                    }}
                                                >
                                                    <img src={blackClose} className={'white-close'} alt={'Close'} />
                                                </div>
                                                {schedule.error ? (
                                                    <div
                                                        className={`${index === schedules.length - 1 ? 'error-message-last' : 'error-message-new'} f-10`}
                                                    >
                                                        {schedule.error}
                                                    </div>
                                                ) : (
                                                    validationErrors.includes(index) &&
                                                    (schedule.selectedStartTime === '00:00' ||
                                                        (schedule.selectedStartTime !== '00:00' &&
                                                            schedule.selectedEndTime === '00:00')) && (
                                                        <div
                                                            className={`${index === schedules.length - 1 ? 'error-message-last' : 'error-message-new'} f-10`}
                                                        >
                                                            {'Please add the slot to proceed'}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="addDays">
                                        <Button
                                            text="+ Add days"
                                            className={`f-bol add-days-btn f-12 ${canAddDay() ? '' : 'disabled'}`}
                                            onClick={handleAddDay}
                                            disabled={!canAddDay()}
                                        />
                                    </div>
                                    <div className="form-section">
                                        <div className="mtop-10 text-center">
                                            <span className="text-calendar f-bol f-14">
                                                {calendarConnected ? 'Connected to Calendar' : 'Connect your Calendar'}
                                                <span className="asterisk">*</span>
                                            </span>
                                        </div>
                                        <hr className="hr-style" />
                                        {!calendarConnected && (
                                            <span className="d-center f-12 f-sem-bol mtop-10">
                                                Connect your calendar to share your interview availability.
                                            </span>
                                        )}
                                        <div className="calendar-buttons">
                                            {calendarConnected === 'Google' && (
                                                <GMButton
                                                    image={googleLogo}
                                                    tickLogo={tickLogo}
                                                    isImage={true}
                                                    text="Google"
                                                    className="add-slot-calendar isgoogle-microsoft f-16 f-sem-bol d-center"
                                                />
                                            )}
                                            {calendarConnected === 'Microsoft' && (
                                                <GMButton
                                                    image={microsoftLogo}
                                                    tickLogo={tickLogo}
                                                    isImage={true}
                                                    text="Microsoft"
                                                    className="add-slot-calendar isgoogle-microsoft f-16 f-sem-bol d-center"
                                                />
                                            )}
                                            {!calendarConnected && (
                                                <>
                                                    <Button
                                                        image={googleLogo}
                                                        isImage={true}
                                                        text="Google"
                                                        className="add-slot-calendar f-16 f-sem-bol d-center"
                                                        onClick={() => onConnect('Google')}
                                                    />
                                                    <img src={orImg} />
                                                    <Button
                                                        image={microsoftLogo}
                                                        isImage={true}
                                                        text="Microsoft"
                                                        className="add-slot-calendar f-16 f-sem-bol d-center"
                                                        onClick={() => onConnect('Microsoft')}
                                                    />
                                                </>
                                            )}
                                        </div>
                                        {calendarConnected && (
                                            <span className="d-center f-12 f-sem-bol">
                                                Your calendar is now connected. Kindly click on "Save" button to share
                                                your availability.
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="save-reset-btn-div">
                                <Button
                                    text="Save"
                                    className="save-btn f-14 f-sem-bol d-center cursor-pointer"
                                    onClick={handlePanelistSlots}
                                />
                                <Button
                                    text="Reset"
                                    className="reset-btn f-14 f-sem-bol d-center cursor-pointer"
                                    onClick={() => setIsClearPopup(true)}
                                />
                            </div>
                        </div>
                        {isClearPopup && (
                            <ClearPopup
                                setIsClearPopup={setIsClearPopup}
                                handleReset={() => {
                                    handleSlotsSubmit(true);
                                }}
                            />
                        )}
                        {isEmailMismatch && (
                            <Popup
                                isClearPopup={isEmailMismatch}
                                setIsClearPopup={setIsEmailMismatch}
                                popupImg={featuredimg}
                                firstBtnContent={'Close'}
                                handleFirstButtonClick={() => {
                                    navigate(location.pathname, { state: null, replace: true });
                                    setIsEmailMismatch(false);
                                }}
                                firstButtonClass="normalBtn"
                                header={'Email ID Mismatch'}
                                content={`Kindly sync registered email id ${interviewerData?.email} to proceed.`}
                            />
                        )}
                        {isSavePopup && (
                            <Popup
                                isClearPopup={isSavePopup}
                                setIsClearPopup={setIsSavePopup}
                                popupImg={tickImg}
                                firstBtnContent={'Close'}
                                handleFirstButtonClick={() => {
                                    navigate(location.pathname, { state: null, replace: true });
                                    setIsSavePopup(false);
                                }}
                                firstButtonClass="normalBtn"
                                header={'Calendar Connected'}
                                content={`Congratulations! Your ${calendarConnected ? calendarConnected : ''} calendar is now connected. Please click 'Save' to proceed.`}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

const ClearPopup = ({ setIsClearPopup, handleReset }) => {
    return (
        <div className="reset-popup">
            <div className="reset-clear-popup rounded position-relative">
                <img
                    className="close-btn cursor-pointer"
                    src={blackClose}
                    alt="close-button"
                    onClick={() => {
                        setIsClearPopup(false);
                    }}
                ></img>
                <div className="d-flex justify-content-center">
                    <img src={featuredimg} alt="featured-icon"></img>
                </div>
                <div className="f-sem-bol f-18 mar-top-16 text-center">Reset All Slots</div>
                <div className="f-14">Are you sure you want to reset all the slots. This action can not be undo.</div>
                <div className="reset-popup-btn-container mar-top-32 d-flex">
                    <button
                        className="cpb-white cpb rounded f-16"
                        onClick={() => {
                            setIsClearPopup(false);
                        }}
                    >
                        <span className="f-16">Close</span>
                    </button>
                    <button
                        className="cpb-red cpb rounded f-16"
                        onClick={() => {
                            handleReset();
                            setIsClearPopup(false);
                        }}
                    >
                        <span className="f-16">Reset</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default InterviewerCalendarSync;
