import {
    GET_INTERVIEWER_CALENDAR,
    POST_INTERVIEWER_CALENDAR,
    VERIFY_CALENDAR_REQUEST,
    POST_CANDIDATE_CALENDAR,
    GET_INTERVIEWER_CALENDAR_SUCCESS,
    POST_INTERVIEWER_CALENDAR_SUCCESS,
    VERIFY_CALENDAR_REQUEST_SUCCESS,
    POST_CANDIDATE_CALENDAR_SUCCESS,
    GET_INTERVIEWER_CALENDAR_FAILURE,
    POST_INTERVIEWER_CALENDAR_FAILURE,
    VERIFY_CALENDAR_REQUEST_FAILURE,
    POST_CANDIDATE_CALENDAR_FAILURE,
    GET_RESCHEDULE_DATA,
    GET_RESCHEDULE_DATA_SUCCESS,
    GET_RESCHEDULE_DATA_FAILURE,
    POST_RESCHEDULE_DATA,
    POST_RESCHEDULE_DATA_SUCCESS,
    POST_RESCHEDULE_DATA_FAILURE,
    CALENDAR_CONNECT,
    CALENDAR_CONNECT_SUCCESS,
    CALENDAR_CONNECT_FAILURE,
    CHECK_CALENDAR_CONNECTION,
    CHECK_CALENDAR_CONNECTION_SUCCESS,
    CHECK_CALENDAR_CONNECTION_FAILURE,
    CANCEL_CALENDAR_INTERVIEW,
    CANCEL_CALENDAR_INTERVIEW_SUCCESS,
    CANCEL_CALENDAR_INTERVIEW_FAILURE,
    RESCHEDULE_CALENDAR_INTERVIEW,
    RESCHEDULE_CALENDAR_INTERVIEW_SUCCESS,
    RESCHEDULE_CALENDAR_INTERVIEW_FAILURE,
    PANELIST_SLOTS,
    PANELIST_SLOTS_SUCCESS,
    PANELIST_SLOTS_FAILURE,
    VERIFY_NEW_CALENDAR_REQUEST,
    VERIFY_NEW_CALENDAR_REQUEST_SUCCESS,
    VERIFY_NEW_CALENDAR_REQUEST_FAILURE,
} from '../action/types';

const INIT_STATE = {
    loading: false,
    interviewerCalendarData: null,
    verifyCalendarRequest: null,
    verifyNewCalendarRequest: null,
    rescheduleData: null,
    calendarConnected: null,
    viewPanelistData: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_INTERVIEWER_CALENDAR:
            return { ...state, loading: true };
        case GET_INTERVIEWER_CALENDAR_SUCCESS:
            return {
                ...state,
                loading: false,
                interviewerCalendarData: action.payload,
            };
        case GET_INTERVIEWER_CALENDAR_FAILURE:
            return { ...state, loading: false };

        case POST_INTERVIEWER_CALENDAR:
            return { ...state, loading: true };
        case POST_INTERVIEWER_CALENDAR_SUCCESS:
            return { ...state, loading: false };
        case POST_INTERVIEWER_CALENDAR_FAILURE:
            return { ...state, loading: false };

        case VERIFY_CALENDAR_REQUEST:
            return { ...state, loading: true };
        case VERIFY_CALENDAR_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                verifyCalendarRequest: action.payload,
            };
        case VERIFY_CALENDAR_REQUEST_FAILURE:
            return { ...state, loading: false };

        case VERIFY_NEW_CALENDAR_REQUEST:
            return { ...state, loading: true };
        case VERIFY_NEW_CALENDAR_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                verifyNewCalendarRequest: action.payload,
            };
        case VERIFY_NEW_CALENDAR_REQUEST_FAILURE:
            return { ...state, loading: false };

        case POST_CANDIDATE_CALENDAR:
            return { ...state, loading: true };
        case POST_CANDIDATE_CALENDAR_SUCCESS:
            return { ...state, loading: false };
        case POST_CANDIDATE_CALENDAR_FAILURE:
            return { ...state, loading: false };

        case GET_RESCHEDULE_DATA:
            return { ...state, loading: true };
        case GET_RESCHEDULE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                rescheduleData: action.payload,
            };
        case GET_RESCHEDULE_DATA_FAILURE:
            return { ...state, loading: false };

        case POST_RESCHEDULE_DATA:
            return { ...state, loading: true };
        case POST_RESCHEDULE_DATA_SUCCESS:
            return { ...state, loading: false };
        case POST_RESCHEDULE_DATA_FAILURE:
            return { ...state, loading: false };

        case CALENDAR_CONNECT:
            return { ...state, loading: true };
        case CALENDAR_CONNECT_SUCCESS:
            return { ...state, loading: false, calendarConnected: action?.payload?.connected };
        case CALENDAR_CONNECT_FAILURE:
            return { ...state, loading: false };

        case CHECK_CALENDAR_CONNECTION:
            return { ...state, loading: true };
        case CHECK_CALENDAR_CONNECTION_SUCCESS:
            return { ...state, loading: false, calendarConnected: action?.payload?.mode };
        case CHECK_CALENDAR_CONNECTION_FAILURE:
            return { ...state, loading: false };
        case CANCEL_CALENDAR_INTERVIEW:
            return { ...state, loading: true };
        case CANCEL_CALENDAR_INTERVIEW_SUCCESS:
            return { ...state, loading: false };
        case CANCEL_CALENDAR_INTERVIEW_FAILURE:
            return { ...state, loading: false };
        case RESCHEDULE_CALENDAR_INTERVIEW:
            return { ...state, loading: true };
        case RESCHEDULE_CALENDAR_INTERVIEW_SUCCESS:
            return { ...state, loading: false };
        case RESCHEDULE_CALENDAR_INTERVIEW_FAILURE:
            return { ...state, loading: false };
        case PANELIST_SLOTS:
            return { ...state, loading: true };
        case PANELIST_SLOTS_SUCCESS:
            return { ...state, loading: false, viewPanelistData: action?.payload };
        case PANELIST_SLOTS_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
