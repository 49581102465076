import { duration } from "moment";

export const fromTimeInd = -1;
export const toTimeInd = -1;
export const selectDays = [
    'All Days',
    'Mondays - Fridays',
    'Saturdays - Sundays',
    'Mondays',
    'Tuesdays',
    'Wednesdays',
    'Thursdays',
    'Fridays',
    'Saturdays',
    'Sundays',
];
export const satSunSelect = [
    'Saturdays - Sundays',
    'Saturdays',
    'Sundays',
];
export const monFriSelect = [
    'All Days',
    'Mondays - Fridays',
    'Mondays',
    'Tuesdays',
    'Wednesdays',
    'Thursdays',
    'Fridays',
];
export const officeTimings =[
    '00:00',
    '00:15',
    '00:30',
    '00:45',
    '01:00',
    '01:15',
    '01:30',
    '01:45',
    '02:00',
    '02:15',
    '02:30',
    '02:45',
    '03:00',
    '03:15',
    '03:30',
    '03:45',
    '04:00',
    '04:15',
    '04:30',
    '04:45',
    '05:00',
    '05:15',
    '05:30',
    '05:45',
    '06:00',
    '06:15',
    '06:30',
    '06:45',
    '07:00',
    '07:15',
    '07:30',
    '07:45',
    '08:00',
    '08:15',
    '08:30',
    '08:45',
    '09:00',
    '09:15',
    '09:30',
    '09:45',
    '10:00',
    '10:15',
    '10:30',
    '10:45',
    '11:00',
    '11:15',
    '11:30',
    '11:45',
    '12:00',
    '12:15',
    '12:30',
    '12:45',
    '13:00',
    '13:15',
    '13:30',
    '13:45',
    '14:00',
    '14:15',
    '14:30',
    '14:45',
    '15:00',
    '15:15',
    '15:30',
    '15:45',
    '16:00',
    '16:15',
    '16:30',
    '16:45',
    '17:00',
    '17:15',
    '17:30',
    '17:45',
    '18:00',
    '18:15',
    '18:30',
    '18:45',
    '19:00',
    '19:15',
    '19:30',
    '19:45',
    '20:00',
    '20:15',
    '20:30',
    '20:45',
    '21:00',
    '21:15',
    '21:30',
    '21:45',
    '22:00',
    '22:15',
    '22:30',
    '22:45',
    '23:00',
    '23:15',
    '23:30',
    '23:45',
];
export const timeSlots = [
    '09:00',
    '09:15',
    '09:30',
    '09:45',
    '10:00',
    '10:15',
    '10:30',
    '10:45',
    '11:00',
    '11:15',
    '11:30',
    '11:45',
    '12:00',
    '12:15',
    '12:30',
    '12:45',
    '13:00',
    '13:15',
    '13:30',
    '13:45',
    '14:00',
    '14:15',
    '14:30',
    '14:45',
    '15:00',
    '15:15',
    '15:30',
    '15:45',
    '16:00',
    '16:15',
    '16:30',
    '16:45',
    '17:00',
    '17:15',
    '17:30',
    '17:45',
    '18:00',
    '18:15',
    '18:30',
    '18:45',
    '19:00',
    '19:15',
    '19:30',
    '19:45',
    '20:00',
    '20:15',
    '20:30',
    '20:45',
    '21:00',
    '21:15',
    '21:30',
    '21:45',
    '22:00',
    '22:15',
    '22:30',
    '22:45',
    '23:00',
    '23:15',
    '23:30',
    '23:45',
    '00:00',
    '00:15',
    '00:30',
    '00:45',
    '01:00',
    '01:15',
    '01:30',
    '01:45',
    '02:00',
    '02:15',
    '02:30',
    '02:45',
    '03:00',
    '03:15',
    '03:30',
    '03:45',
    '04:00',
    '04:15',
    '04:30',
    '04:45',
    '05:00',
    '05:15',
    '05:30',
    '05:45',
    '06:00',
    '06:15',
    '06:30',
    '06:45',
    '07:00',
    '07:15',
    '07:30',
    '07:45',
    '08:00',
    '08:15',
    '08:30',
    '08:45',
];
export const rescheduleTimeSlots = [
    '10:00',
    '10:15',
    '10:30',
    '10:45',
    '11:00',
    '11:15',
    '11:30',
    '11:45',
    '12:00',
    '12:15',
    '12:30',
    '12:45',
    '13:00',
    '13:15',
    '13:30',
    '13:45',
    '14:00',
    '14:15',
    '14:30',
    '14:45',
    '15:00',
    '15:15',
    '15:30',
    '15:45',
    '16:00',
    '16:15',
    '16:30',
    '16:45',
    '17:00',
    '17:15',
    '17:30',
    '17:45',
    '18:00',
    '18:15',
    '18:30',
    '18:45',
    '19:00',
    '19:15',
    '19:30',
    '19:45',
    '20:00',
    '20:15',
    '20:30',
    '20:45',
    '21:00',
];

export const displayTimeSlots = {
    '00:00': '12:00 AM',
    '00:15': '12:15 AM',
    '00:30': '12:30 AM',
    '00:45': '12:45 AM',
    '01:00': '01:00 AM',
    '01:15': '01:15 AM',
    '01:30': '01:30 AM',
    '01:45': '01:45 AM',
    '02:00': '02:00 AM',
    '02:15': '02:15 AM',
    '02:30': '02:30 AM',
    '02:45': '02:45 AM',
    '03:00': '03:00 AM',
    '03:15': '03:15 AM',
    '03:30': '03:30 AM',
    '03:45': '03:45 AM',
    '04:00': '04:00 AM',
    '04:15': '04:15 AM',
    '04:30': '04:30 AM',
    '04:45': '04:45 AM',
    '05:00': '05:00 AM',
    '05:15': '05:15 AM',
    '05:30': '05:30 AM',
    '05:45': '05:45 AM',
    '06:00': '06:00 AM',
    '06:15': '06:15 AM',
    '06:30': '06:30 AM',
    '06:45': '06:45 AM',
    '07:00': '07:00 AM',
    '07:15': '07:15 AM',
    '07:30': '07:30 AM',
    '07:45': '07:45 AM',
    '08:00': '08:00 AM',
    '08:15': '08:15 AM',
    '08:30': '08:30 AM',
    '08:45': '08:45 AM',
    '09:00': '09:00 AM',
    '09:15': '09:15 AM',
    '09:30': '09:30 AM',
    '09:45': '09:45 AM',
    '10:00': '10:00 AM',
    '10:15': '10:15 AM',
    '10:30': '10:30 AM',
    '10:45': '10:45 AM',
    '11:00': '11:00 AM',
    '11:15': '11:15 AM',
    '11:30': '11:30 AM',
    '11:45': '11:45 AM',
    '12:00': '12:00 PM',
    '12:15': '12:15 PM',
    '12:30': '12:30 PM',
    '12:45': '12:45 PM',
    '13:00': '01:00 PM',
    '13:15': '01:15 PM',
    '13:30': '01:30 PM',
    '13:45': '01:45 PM',
    '14:00': '02:00 PM',
    '14:15': '02:15 PM',
    '14:30': '02:30 PM',
    '14:45': '02:45 PM',
    '15:00': '03:00 PM',
    '15:15': '03:15 PM',
    '15:30': '03:30 PM',
    '15:45': '03:45 PM',
    '16:00': '04:00 PM',
    '16:15': '04:15 PM',
    '16:30': '04:30 PM',
    '16:45': '04:45 PM',
    '17:00': '05:00 PM',
    '17:15': '05:15 PM',
    '17:30': '05:30 PM',
    '17:45': '05:45 PM',
    '18:00': '06:00 PM',
    '18:15': '06:15 PM',
    '18:30': '06:30 PM',
    '18:45': '06:45 PM',
    '19:00': '07:00 PM',
    '19:15': '07:15 PM',
    '19:30': '07:30 PM',
    '19:45': '07:45 PM',
    '20:00': '08:00 PM',
    '20:15': '08:15 PM',
    '20:30': '08:30 PM',
    '20:45': '08:45 PM',
    '21:00': '09:00 PM',
    '21:15': '09:15 PM',
    '21:30': '09:30 PM',
    '21:45': '09:45 PM',
    '22:00': '10:00 PM',
    '22:15': '10:15 PM',
    '22:30': '10:30 PM',
    '22:45': '10:45 PM',
    '23:00': '11:00 PM',
    '23:15': '11:15 PM',
    '23:30': '11:30 PM',
    '23:45': '11:45 PM',
};
export const interviewDuration=[
    '30 mins',
    '45 mins',
    '60 mins',
];
export const TIME_SLOT_STATUS = {
    AVAILABLE: 'available',
};

export const GENERAL_DATE_TIME_FORMAT = 'YYYY-MM-DDT00:00:00.000Z';
